import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions, FEATURE} from '../../redux/actions/documents';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import {CommandActions as DocumentsCommandActions, DocumentActions as DocumentDocumentsActions} from '../../redux/actions/documents';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CommandBar from './command_bar/index'
import Notification from '../notification/index';
import { getNotification } from '../../redux/selectors/notification';
import { getDrawerOpened, getFilterPanelOpened } from '../../redux/reducers/ui';
import { getLoader } from '../../redux/selectors/loader';
import { IColumn, MessageBarType, ProgressIndicator, Spinner, SpinnerSize, Selection } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import { getColumns } from '../documents/columns';
import SelectedFilters from '../filtersPanel/filters/selectedFilters';
import _ from 'lodash';
import { getOrderColumn, getTotal } from '../../redux/reducers/documents';
import DocumentModal from '../modals/document'
import DocumentsGrid from '../documents/index'
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { getFilters } from '../../redux/reducers/filters';
import { Filters } from '../../models/filters';
import { getNotificationWidth } from '../../redux/selectors/ui';
import CalloutFilters from '../filtersPanel/callout/index';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import UploadFiles from '../documents/functionalities/uploadReplaceFile';
import ChangeStatus from '../documents/functionalities/changeStatus';
import EditDocument from '../documents/functionalities/editDocument';
import DownloadFiles from '../documents/functionalities/downloadFiles';
import DownloadComments from '../documents/functionalities/downloadComments';
import DownloadExcelReport from '../documents/functionalities/downloadExcelReport';
import { InternalRoleActionsType, ExternalRoleActionsType, getTheme } from '../../utils/costants';
import { getHistoryName } from '../../utils/functions';
import { getContextualDocuments } from '../../redux/selectors/documents';
import { FileToDelete, EditDocumentForm, Document, getEmptyFileForm, FileForm } from '../../models/document';
import { Status } from '../../models/status';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { getDarkModeEnabled, getHasPartner, getSelectedProjectId } from '../../redux/reducers/project';
import { getSelectedProjectDaysToApproval } from '../../redux/selectors/project';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";

let GenericModal = GenericModalGenerator();
export interface SearchProps extends RouteComponentProps, WithTranslation {
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    documents: Document[]
    filterPanelOpened: boolean, 
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    filters: Filters,
    showCallout: boolean,
    orderColumn: {name: string, direction: string },
    total: number,
    changeStatusForm?: {
        newStatus: Status,
        fileForm?: FileForm,
        files?: any[]
    },
    notificationWidth: string,
    email?: string,
    darkModeEnabled: boolean,
    hasPartner: boolean,
    daysToApproval: number,
    selectedProjectid?: number,
    setSelectedItemsIds: typeof DocumentActions.setSelectedItemsIds,
    getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal,
    showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    closeGenericModal: typeof ModalActions.CommandActions.closeModal,
    downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
    changeStatus: typeof DocumentsCommandActions.changeStatus,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    setSortColumn: typeof DocumentDocumentsActions.setSortColumn,
    downloadFiles: typeof DocumentsCommandActions.downloadFiles,
    downloadComments: typeof DocumentsCommandActions.downloadComments,
    downloadExcelReport: typeof DocumentsCommandActions.downloadExcelReport
}
class Search extends React.Component<SearchProps & RouteComponentProps> {
    constructor(props: SearchProps) {
        super(props);   
    }

    getKey = (item: any, index?: number): string => {
        return item.id;
    }

    getFilters = (): Filters => {
        const { filters } = this.props;
        return filters;
    }

    getFilterPanel = (): boolean => {
        const { filterPanelOpened } = this.props;
        return filterPanelOpened;
    }

    onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { fetchDocuments, setSortColumn } = this.props; 
        setSortColumn({colName: column.fieldName as string, direction: (column.isSortedDescending) ? 'asc' : 'desc', feature: FEATURE});
        //this.selection.setAllSelected(false);    
        fetchDocuments({});
    };

    selection = new Selection({		
        getKey: this.getKey,
		onSelectionChanged: () => {
            let rows = this.selection.getSelection() as any;
            const {setSelectedItemsIds} = this.props;
            let ids = (rows) ? _.map(rows, (r: Document) => {return r.id as number}) : [];
            setSelectedItemsIds({ids: _.map(ids, id => id.toString()), feature: FEATURE}) 
		}
    });

    render(){
        const {loading, loadingContent, showNotification, type, message, filterPanelOpened, orderColumn, loadingScroll, userProjectActions, total, changeStatusForm, documents, t, email,
               history, notificationWidth, hasPartner, daysToApproval, selectedProjectid, getDocumentAndOpenModal, showOrHideCallout, closeGenericModal, downloadOrShowFile, changeStatus, openGenericModal,
               downloadFiles, downloadComments, downloadExcelReport} = this.props;
        const styles = getClassNames(filterPanelOpened);
        const historyName = getHistoryName(history);
        const disableDownloald = !((documents.length === 0 && total < 20) || (documents.length > 0 && documents.length < 20));
        const canRead = _.find(userProjectActions, a => a === 'ReadDashboard') ? true : false;
        const canDeleteFiles = _.find(userProjectActions, a => a === 'UploadAttachmentDashboard' || a === 'UploadCommentFileDashboard') ? true : false;
        const columns = getColumns(selectedProjectid || 0, email as string, t, canRead, canDeleteFiles, this.selection, this.getFilterPanel, getDocumentAndOpenModal, this.getFilters, showOrHideCallout, downloadOrShowFile, this.onColumnClick, orderColumn, historyName, openGenericModal, undefined, undefined, _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId'), getTheme(false), hasPartner, daysToApproval);
        return (
            <>
                <CommandBar userProjectActions={userProjectActions} loading={loading} loadingContent={loadingContent} loadingScroll={loadingScroll}/>
                <div style={{position: 'absolute', width: notificationWidth, top: 99, zIndex: 10}}>
                    {!loading && !loadingContent && <Notification name={'supplierMaterial'} show={showNotification} type={type} message={message}/>}
                </div>
                {!loading && <SelectedFilters selection={this.selection}/>}
                <ProgressIndicator styles={{root: {height: 10}}} progressHidden={ !loadingContent || loading }/> 
                {loading && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}
                {!loading &&
                    <div className={styles.resultContainer}>
                        <DocumentsGrid selection={this.selection} columns={columns} loadingScroll={loadingScroll}/>
                        <DocumentModal userProjectActions={userProjectActions}/>
                    </div>
                }
                <CalloutFilters/>
                <GenericModal 
                    id={'changeStatus'}
                    title={t('Change Status')}
                    message={''}                 
                    onClose={() => {}}
                    btnCloseText={t('Cancel')}
                    onConfirm={() => {changeStatus({documents})}}
                    btnConfirmText={t('Save')}
                    customComponent={<ChangeStatus loadingContent={loadingContent}/>}
                    disableConfirm={!changeStatusForm || (changeStatusForm.newStatus && (changeStatusForm.newStatus.name === 'supplier-declined') && (!changeStatusForm.newStatus.comment && (!changeStatusForm.files || changeStatusForm.files.length === 0)))}
                    width={640}
                />
                <GenericModal 
                    id={'fileName'}
                    title={(!disableDownloald) ? t('Download files') : t('Attention')}
                    message={(!disableDownloald) ? '' : t('There are too many documents to download. Please select a number of documents less than 20')}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadFiles()}}
                    btnConfirmText={(!disableDownloald) ? t('Download') : undefined}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadFiles disableDownloald={disableDownloald}/>}
                    disableConfirm={loadingContent || disableDownloald}     
                />
                <GenericModal 
                    id={'comments'}
                    title={t('Download comments')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadComments()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadComments/>}
                    disableConfirm={loadingContent}     
                />
                <GenericModal 
                    id={'excelReport'}
                    title={t('Download Excel report')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadExcelReport()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadExcelReport/>}
                    disableConfirm={loadingContent}          
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('supplierMaterial')(state).loading,
    loadingContent: (state) => getLoader('supplierMaterial')(state).loadingContent,
    loadingScroll: (state) => getLoader('supplierMaterial')(state).loadingScroll,
    showNotification: (state) => getNotification('supplierMaterial')(state).show,
    type: (state) => getNotification('supplierMaterial')(state).type,
    message: (state) => getNotification('supplierMaterial')(state).message,
    filterPanelOpened: getFilterPanelOpened,
    userProjectActions: getSelectedProjectUserActions,
    filters: getFilters,
    changeStatusForm: (state) => (getGenericModal('changeStatus')(state)) ? (getGenericModal('changeStatus')(state) as any).contextItem : undefined,
    orderColumn: getOrderColumn,
    total: getTotal,
    documents: getContextualDocuments,
    notificationWidth: getNotificationWidth,
    email: getEmail,
    darkModeEnabled: getDarkModeEnabled,
    hasPartner: getHasPartner,
    daysToApproval: getSelectedProjectDaysToApproval,
    selectedProjectid: getSelectedProjectId
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setSelectedItemsIds: DocumentActions.setSelectedItemsIds,
        getDocumentAndOpenModal: DocumentCommandActions.getDocumentAndOpenModal,
        showOrHideCallout: FiltersCommandActions.showOrHideCallout,
        openGenericModal: ModalActions.CommandActions.openModal,
        closeGenericModal: ModalActions.CommandActions.closeModal,
        downloadOrShowFile: DocumentsCommandActions.downloadOrShowFile,
        changeStatus: DocumentsCommandActions.changeStatus,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        setSortColumn: DocumentDocumentsActions.setSortColumn,
        downloadFiles: DocumentsCommandActions.downloadFiles,
        downloadComments: DocumentsCommandActions.downloadComments,
        downloadExcelReport: DocumentsCommandActions.downloadExcelReport,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Search)));