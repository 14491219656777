 import React from 'react';
import { IColumn, IDetailsColumnProps } from '@fluentui/react';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import _ from 'lodash';
import { FontIcon, FontSizes, Icon, IconButton, IContextualMenuListProps, IContextualMenuProps, Link, Selection, Text, TooltipHost } from '@fluentui/react';
import { Document, FileToDelete, getEmptyParentFileForm, getParentFileForm } from '../../models/document';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import { Filters } from '../../models/filters';
import { HistoryPathType, isExternalApp } from '../../utils/costants';
import { getClassNames } from './index.classNames';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { CommandActions as DocumentsCommandActions } from '../../redux/actions/documents';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { formatDate, getFileExtension, showParentFileChangeIcon, showStatusFilter } from '../../utils/functions';
import { TFunction } from 'i18next';
import { Folder } from '../../models/folder';

const deleteFile = (id: string, fileToDelete: FileToDelete, email?: string,  openGenericModal?: typeof ModalActions.CommandActions.openModal) => {
  if(fileToDelete && fileToDelete.file && fileToDelete.file.createdBy && fileToDelete.file.createdBy !== email)
    window.location.href = "mailto:" + fileToDelete.file.createdBy + "?subject=Exod - File " + fileToDelete.file.fileName + " deleted by " + email;
  if(openGenericModal)
    openGenericModal({id: 'deleteFile', optional: {...fileToDelete}})
}


export const getColumns = (selectedProjectid: number,
                           email: string,
                           t: TFunction,
                           canRead: boolean,
                           canDeleteFiles: boolean,
                           selection: Selection,
                           getFilterPanel: any, 
                           getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal, 
                           getFilters: () => Filters, 
                           showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout, 
                           downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
                           handleClick: any,
                           orderedColumn: {name: string, direction: string},
                           historyName: HistoryPathType,
                           openGenericModal?: typeof ModalActions.CommandActions.openModal,
                           canUploadParent?: boolean,
                           folders?: Folder[],
                           isPartner?: boolean,
                           theme?: any,
                           projectHasPartner?: boolean,
                           daysToApproval?: number) => {
      const styles = getClassNames(false);

      const filterPanelOpened = getFilterPanel() as boolean;

      const colums: IColumn[] = [
          ...(canUploadParent ? [{
            key: 'uploadParent',
            name: 'Upload Parent',
            fieldName: 'uploadParent',
            minWidth: 5,
            maxWidth: 5,
            isResizable: false,
            isIconOnly: true,
            data: 'number',
            isPadded: false,
            onRender: (item: Document) => {
              if(item.status && (item.status.name === 'reserved' || item.status.name === 'supplier-declined' || item.status.name === 'partner-reserved')){
                return (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                      {openGenericModal && <FontIcon iconName={'Upload'} title={t('Upload file')} onClick={() => {
                          openGenericModal({id: 'uploadParent', contextItem: {files: [], fileForm: getParentFileForm(item, item.status && item.status.name === 'partner-reserved')}})
                        }
                      } style={{fontSize: FontSizes.xLarge, color: theme.palette.themePrimary, cursor: 'pointer'}} ></FontIcon>}
                  </div>
                );              
              }
            }
          }] : []),   
          ...(canRead ? [{
            key: 'filesNumber',
            name: t('Files'),
            fieldName: 'filesNumber',
            minWidth: 45,
            maxWidth: 45,
            isResizable: false,
            isIconOnly: true,
            //onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'number',
            isPadded: false,
            onRender: (item: Document) => {

              let files = (item.files && item.files.length > 0) ? _.orderBy(item.files, ['isParent', 'isComment', 'fileName'], ['desc', 'asc', 'asc']) : [];
              let parentFile = (item.files && item.files.length > 0) ? _.find(item.files, f => f.isParent) : undefined;
              let idMenuButtonsProps: {id: string, menuButtonProps: IContextualMenuProps}[] = [];
              //let menuButtonProps: IContextualMenuProps = {items: [], directionalHintFixed: true}
       
              _.forEach(files, a => {

                let idMenuButtonsProp: {id: string, menuButtonProps: IContextualMenuProps} = {
                  id: a.id,
                  menuButtonProps: {
                    items: [
                      {
                        key: 'docNumbName',
                        text: 'Use document number as filename',
                        onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName})}
                      },
                      {
                        key: 'clientNumbName',
                        text: 'Use client number as filename',
                        onClick: () => {downloadOrShowFile({id: a.id, fileName: a.fileName, clientNumber: item.clientNumber})}
                      },
                    ],
                    directionalHintFixed: true,
                  }
                };
                idMenuButtonsProps = [...idMenuButtonsProps, idMenuButtonsProp];            
              });

              const menuProps: IContextualMenuProps = {
                  items: _.map(files, a => {return {key: a.id as string, text: a.fileName, }}),
                  directionalHintFixed: true,
                  onRenderMenuList: (props?: IContextualMenuListProps, defaultRender?: any ) => { return (files && files.length > 0 && props && props.items.length > 0) ? <>{
                          _.map(files, (a, index) => {
                              let aaa = getFileTypeIconProps({extension: (a.fileName) ? getFileExtension(a.fileName) : '', size: 16});
                              let btnProps = (_.find(idMenuButtonsProps, mb => mb.id === a.id) as {id: string, menuButtonProps: IContextualMenuProps}).menuButtonProps;
                              let fileToDelete: FileToDelete = {documentId: item.id, file: {...a}};
                              return  <div key={index}>
                                          <div className={styles.attachment} style={{display: 'flex', justifyContent: 'space-between', padding: 8, alignItems: 'center', gap: 15}}>  
                                              <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Icon key={index + '_i'} {...getFileTypeIconProps({extension: (a.fileName) ? getFileExtension(a.fileName) : '', size: 16}) }/>
                                                <Text key={index + '_t'} variant="smallPlus" style={{marginLeft: 10, fontWeight: (a.isParent) ? 'bold' : undefined}}>{a.fileName}</Text> 
                                              </div>
                                              <div style={{display: 'flex', alignItems: 'center', gap: (!a.isParent) ? 15 : 5}}>
                                                {canDeleteFiles && item.editable && item.status && item.status.name !== 'canceled' && openGenericModal && !a.isParent && <FontIcon iconName={"Cancel"} title={t("Delete file")} style={{cursor: "pointer", color: 'red', marginTop: 2 }} onClick={() => {deleteFile('deleteFile', fileToDelete, email, openGenericModal)}}/>  }
                                                <Text key={index + '_p'} variant="smallPlus" style={{}}>{(a.isComment) ? '[C]' : ((!a.isComment && !a.isParent) ? '[A]' : '')}</Text>
                                                {!isExternalApp && item.editable && canDeleteFiles && item.status && item.status.name !== 'canceled' && showParentFileChangeIcon(historyName) && a.isParent && openGenericModal && <FontIcon iconName={"ChangeEntitlements"} title={t("Replace file")} style={{cursor: "pointer", color: '#1E20C2', marginTop: 2 }} onClick={() => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyParentFileForm(a.fileName)}, optional: {replaceId: a.id}})}}/>  }
                                                {a.isParent && 
                                                  <IconButton
                                                    menuProps={btnProps}
                                                    iconProps={{iconName: 'Download'}}
                                                    title="Download"
                                                    ariaLabel="Download"
                                                    className={styles.menuButton}
                                                    styles={{menuIconHovered: styles.menuButton}}
                                                    style={{cursor: "pointer", marginTop: 2, height: 5, }}
                                                  />                                               
                                                }                                     
                                                {!a.isParent && <FontIcon iconName={"Download"} title={t("Download file")} style={{cursor: "pointer", color: 'green', marginTop: 2 }}  onClick={() => {downloadOrShowFile({id: a.id, fileName: a.fileName})}}/>}
                                              </div>
                                          </div>                                   
                                      </div>
                          })            
                      }</> : <></>
                  },
                  styles: {root: {maxHeight: 256}}
              };
              return (
                files && files.length > 0 &&
                    <div style={{display: 'flex', alignItems: 'center', height: 21}}>
                      <IconButton menuProps={menuProps} title={'Files'} iconProps={{...getFileTypeIconProps({extension: (parentFile && parentFile.fileName) ? ('.' + parentFile.fileName.split('.')[1]) : '', size: 16}) }} styles={{ menuIcon: {display: 'none'} }}></IconButton>
                      <Text>{files.length}</Text>  
                    </div>                               
              );
            }
          }] : []),     
          {
            key: 'icon',
            name: 'icon',
            fieldName: 'icon',
            minWidth: 25,
            maxWidth: 25,
            isResizable: false,
            isIconOnly: true,
            //onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'number',
            isPadded: false,
            onRender: (item: Document) => {

              let isCommented = (item.comments.length > 0 || _.some(item.files, f => f.isComment));
              let isWithinFolder = (item.folderIds.length > 0) ? true : false;
              const documentFolders = (folders && folders.length > 0 && isWithinFolder) ? _.filter(folders, f => item.folderIds.includes(f.id)) : [];
              
              return ( (isCommented || isWithinFolder) &&
                <div style={{display: 'flex', alignItems: 'center', height: 21, gap: 5}}>
                    {isCommented && <FontIcon iconName={"CommentActive"} title={"Commented"} style={{color: 'green', marginTop: 2 }}/>}
                    {historyName === 'dashboard' && isWithinFolder && <FontIcon iconName={"FabricFolder"} title={(documentFolders.length > 0) ? _.join(_.map(documentFolders, df => df.description), ', ') : ''} style={{color: '#D6A107', marginTop: 2 }}/>}
                </div>                               
              );
            }
          },
          {
            key: 'status',
            name: t('Status'),
            fieldName: 'Status',
            minWidth: 60,
            maxWidth: 60,
            isResizable: false,
            data: 'string',
            //onColumnClick: (handleClick) ? handleClick : () => {},
            isPadded: false,
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  { showStatusFilter(historyName) && 
                    <FontIcon iconName={(filters.status.length > 0) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.status) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'status', ref: _elementRef}); e.stopPropagation();}}/>                 
                  }
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Status').toString()}</Text>
                </div>
              );
            },       
            onRender: (item: Document) => {
              if(item.status){
                return (
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <FontIcon iconName={item.status.icon} title={item.status.description} style={{color: item.status.color, fontSize: FontSizes.xLarge}} ></FontIcon>
                  </div>
                );              
              }
            }
          },
          {
            key: 'date',
            name: t('Date'),
            fieldName: 'date',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && (filters.from || filters.to)) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && (filters.from || filters.to)) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'dates_callout', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Date').toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document) => {
              return (item.status) ? formatDate(item.status.date) : ''
            },
            isSorted: (orderedColumn && orderedColumn.name === 'date') ? true : false,
            isSortedDescending: (orderedColumn && orderedColumn.name === 'date' && orderedColumn.direction === 'desc') ? true : false,
          },
          ...(daysToApproval && daysToApproval > 0 ? [{
            key: 'approvalDate',
            name: t('Approval Deadline'),
            fieldName: 'approvalDate',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && filters.approvalDate) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.approvalDate) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'approvalDate', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Approval Deadline').toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document) => {
              return (item.approvalDate) ? formatDate(item.approvalDate) : ''
            },
            isSorted: (orderedColumn && orderedColumn.name === 'approvalDate') ? true : false,
            isSortedDescending: (orderedColumn && orderedColumn.name === 'approvalDate' && orderedColumn.direction === 'desc') ? true : false,
          }] : []),
          {
            key: 'documentNumber',
            name: t('Doc.Number'),
            fieldName: 'documentNumber',
            minWidth: 255,
            maxWidth: 255,
            isResizable: true,
            onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && filters.documentNumber.length > 0) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.documentNumber.length > 0) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'documentNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Doc.Number').toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document, index?: number) => {       
              if(item.documentNumber){
                return ( 
                    <TooltipHost content={item.documentNumber}> 
                      {canRead && 
                        <Link onClick={() => {
                          if(selection.isAllSelected()){
                            let rows = selection.getSelection() as any[];
                            selection.setAllSelected(false);
                            selection.setIndexSelected(rows.indexOf(item), true, false);                      
                          }
                          getDocumentAndOpenModal({documentId: item.id as number})
                        }}>{item.documentNumber}</Link>       
                      }
                      {!canRead && 
                        <div>{item.documentNumber}</div>
                      }
                    </TooltipHost>);              
              }
            },
            isSorted: (orderedColumn && orderedColumn.name === 'documentNumber') ? true : false,
            isSortedDescending: (orderedColumn && orderedColumn.name === 'documentNumber' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'sheet',
          name: t('Sheet'),
          fieldName: 'sheet',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.sheet) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.sheet) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'sheet', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Sheet').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            return ( <TooltipHost content={(item.sheet) ? _.padStart(item.sheet.toString().toString(), 3, '0'): '000'}> <div>{(item.sheet) ? _.padStart(item.sheet.toString().toString(), 3, '0') : '000'}</div> </TooltipHost>);                        
          },
          isSorted: (orderedColumn && orderedColumn.name === 'sheet') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'sheet' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'revision',
          name: t('Revision'),
          fieldName: 'revision',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.revision) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.revision) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'revision', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Revision').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.revision){
              return ( <div>{item.revision}</div>);                             
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'revision') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'revision' && orderedColumn.direction === 'desc') ? true : false,
        },      
        ...(!isPartner ? [{
          key: 'agileNumber',
          name: t('Agile Number'),
          fieldName: 'agileNumber',
          minWidth: filterPanelOpened ? 145 : 160,
          maxWidth: filterPanelOpened ? 145 : 160,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.agileNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.agileNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'agileNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Agile Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.agileNumber){
              return ( <TooltipHost content={(item.agileNumber) ? item.agileNumber.toLocaleUpperCase() : ''}> <div>{(item.agileNumber) ? item.agileNumber.toLocaleUpperCase() : ''}</div> </TooltipHost>);                       
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'agileNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'agileNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),
        ...(!isExternalApp || (isExternalApp && selectedProjectid === 401179) ? [{
          key: 'clientNumber',
          name: t('Client Number'),
          fieldName: 'clientNumber',
          minWidth: filterPanelOpened ? 140 : 180,
          maxWidth: filterPanelOpened ? 140 : 180,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.clientNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.clientNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'clientNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Client Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.clientNumber){
              return ( <TooltipHost content={item.clientNumber}> <div>{item.clientNumber}</div> </TooltipHost>);                   
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'clientNumber') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'clientNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),
        ...(!isPartner ? [{
            key: 'tdNumber',
            name: t('TD Number'),
            fieldName: 'tdNumber',
            minWidth: filterPanelOpened ? 140 : 200,
            maxWidth: filterPanelOpened ? 140 : 200,
            isResizable: true,
            onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && filters.tdNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.tdNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'tdNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('TD Number').toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document) => {
              if(item.tdNumber){
                return ( <TooltipHost content={item.tdNumber}> <div>{item.tdNumber}</div> </TooltipHost>);                                
              }
            },
            isSorted: (orderedColumn && orderedColumn.name === 'tdNumber') ? true : false,
            isSortedDescending: (orderedColumn && orderedColumn.name === 'tdNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []), 
        ...(!isPartner ? [{
            key: 'publication',
            name: t('Publication'),
            fieldName: 'publication',
            minWidth: filterPanelOpened ? 120 : 200,
            maxWidth: filterPanelOpened ? 120 : 200,
            isResizable: true,
            onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: false,   
            onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
              const filters = getFilters();
              let _elementRef: HTMLElement;
              return (
                <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                  <FontIcon iconName={(filters && filters.publication) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.publication) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'publication', ref: _elementRef}); e.stopPropagation();}}/> 
                  <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Publication').toString()}</Text>
                </div>
              );
            },
            onRender: (item: Document) => {
              if(item.publication){
                return ( <TooltipHost content={item.publication.toString()}> <div>{item.publication.toString()}</div> </TooltipHost>);                          
              }
            },
            isSorted: (orderedColumn && orderedColumn.name === 'publication') ? true : false,
            isSortedDescending: (orderedColumn && orderedColumn.name === 'publication' && orderedColumn.direction === 'desc') ? true : false,
        }] : []), 
        {
          key: 'purpose',
          name: t('Purpose'),
          fieldName: 'purpose',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.purpose) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.purpose) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'purpose', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Purpose').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.purpose){
              return ( <div>{item.purpose.name}</div>);                             
            }
          },
          // isSorted: (orderedColumn && orderedColumn.name === 'purpose') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'purpose' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'title1',
          name: t('Title 1'),
          fieldName: 'titleLine4Pl',
          minWidth: 120,
          maxWidth: 120,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.titleLine4Pl) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.titleLine4Pl) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'titleLine4Pl', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Title 1').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.titleLine4Pl){
              return ( <div>{item.titleLine4Pl}</div>);                             
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'titleLine4Pl') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'titleLine4Pl' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'title2',
          name: t('Title 2'),
          fieldName: 'titleLine5Pl',
          minWidth: 120,
          maxWidth: 120,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.titleLine5Pl) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.titleLine5Pl) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'titleLine5Pl', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Title 1').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.titleLine5Pl){
              return ( <div>{item.titleLine5Pl}</div>);                             
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'titleLine5Pl') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'titleLine5Pl' && orderedColumn.direction === 'desc') ? true : false,
        },
        {
          key: 'title3',
          name: t('Title 3'),
          fieldName: 'titleLine6Pl',
          minWidth: 120,
          maxWidth: 120,
          isResizable: true,
          onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.titleLine6Pl) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.titleLine6Pl) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'titleLine6Pl', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Title 3').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.titleLine6Pl){
              return ( <div>{item.titleLine6Pl}</div>);                             
            }
          },
          isSorted: (orderedColumn && orderedColumn.name === 'titleLine6Pl') ? true : false,
          isSortedDescending: (orderedColumn && orderedColumn.name === 'titleLine6Pl' && orderedColumn.direction === 'desc') ? true : false,
        },
        ...(projectHasPartner && !isExternalApp ? [{
          key: 'partner',
          name: t('Partner'),
          fieldName: 'partner',
          minWidth: 140,
          maxWidth: 140,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.partner) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.partner) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'partner', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Partner').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.partnerName){
              return ( <TooltipHost content={(item.partnerName) ? item.partnerName.toLocaleUpperCase() : ''}> <div>{(item.partnerName) ? item.partnerName.toLocaleUpperCase() : ''}</div> </TooltipHost>);                       
            }
          },
          //isSorted: (orderedColumn && orderedColumn.name === 'partner') ? true : false,
          //isSortedDescending: (orderedColumn && orderedColumn.name === 'partner' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),
        ...(projectHasPartner && !isExternalApp ? [{
          key: 'partnerNumber',
          name: t('Partner Number'),
          fieldName: 'partnerNumber',
          minWidth: 180,
          maxWidth: 180,
          isResizable: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.partnerNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.partnerNumber) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'partnerNumber', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Partner Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.partnerNumber){
              return ( <TooltipHost content={(item.partnerNumber) ? item.partnerNumber.toLocaleUpperCase() : ''}> <div>{(item.partnerNumber) ? item.partnerNumber.toLocaleUpperCase() : ''}</div> </TooltipHost>);                       
            }
          },
          //isSorted: (orderedColumn && orderedColumn.name === 'partnerNumber') ? true : false,
          //isSortedDescending: (orderedColumn && orderedColumn.name === 'partnerNumber' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),
        ...(!isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial') ? [{
          key: 'supplierName',
          name: t('Supplier'),
          fieldName: 'supplierName',
          minWidth: 140,
          maxWidth: 140,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.supplier) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.supplier) ? theme.palette.themePrimary : undefined}} onClick={(e) => {showOrHideCallout({show: true, filter: 'supplier', ref: _elementRef}); e.stopPropagation();}}/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Supplier').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.supplierName){
              return ( <TooltipHost content={(item.supplierName) ? item.supplierName.toLocaleUpperCase() : ''}> <div>{(item.supplierName) ? item.supplierName.toLocaleUpperCase() : ''}</div> </TooltipHost>);                       
            }
          },
          //isSorted: (orderedColumn && orderedColumn.name === 'partner') ? true : false,
          //isSortedDescending: (orderedColumn && orderedColumn.name === 'partner' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),
        ...(((!isExternalApp && (historyName === 'dashboard' || historyName === 'reservedNumbers' || historyName === 'supplierMaterial')) || (isExternalApp && (historyName === 'reservedNumbers' || historyName === 'publishMaterial'))) ? [{
          key: 'supplierDocumentNumber',
          name: t('Supplier Doc.Number'),
          fieldName: 'supplierDocumentNumber',
          minWidth: 240,
          maxWidth: 240,
          isResizable: true,
         // onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRenderHeader: (props?: IDetailsColumnProps): JSX.Element => { 
            const filters = getFilters();
            let _elementRef: HTMLElement;
            return (
              <div ref={elementRef => (_elementRef = elementRef!)}style={{display: 'flex', alignItems: 'center'}}>
                <FontIcon iconName={(filters && filters.supplierDocumentNumber) ? "FilterSolid" : "Filter"} style={{fontSize: 14, cursor: 'pointer', color: (filters && filters.supplierDocumentNumber) ? theme.palette.themePrimary : undefined}} 
                  onClick={(e) => {
                    showOrHideCallout({show: true, filter: 'supplierDocumentNumber', ref: _elementRef}); 
                  e.stopPropagation();}
                }/> 
                <Text style={{fontWeight: 600, marginLeft: 5}}>{t('Supplier Doc.Number').toString()}</Text>
              </div>
            );
          },
          onRender: (item: Document) => {
            if(item.supplierDocumentNumber){
              return ( <TooltipHost content={(item.supplierDocumentNumber) ? item.supplierDocumentNumber : ''}> <div>{(item.supplierDocumentNumber) ? item.supplierDocumentNumber : ''}</div> </TooltipHost>);                       
            }
          },
          //isSorted: (orderedColumn && orderedColumn.name === 'partner') ? true : false,
          //isSortedDescending: (orderedColumn && orderedColumn.name === 'partner' && orderedColumn.direction === 'desc') ? true : false,
        }] : []),
        
    ];
    return colums;
}